import React, { createRef, useEffect, useMemo, useRef, useState } from "react";
import { OptionMenu, PageHeader } from "../molecules";
import { Container } from "@mui/material";
import { ArticleSection } from "../organisms";
import axios from "axios";
import { apiUrls } from "../../services/apiUrls";

export const Blog = () => {
  const [blogsPerCategory, setBlogsPerCategory] = useState([]);
  const [categories, setCategories] = useState();
  const [oneSelectedCategory, setOneSelectedCategory] = useState([]);
  const [selectedCategory, setSelectedCategory] = useState([]);
  const [clickedSeeMore, setClickedSeeMore] = useState(-1);
  const [blogList, setBlogList] = useState([]);
  const [searchText, setSearchText] = useState("");
  const [tagClicked, setTagClicked] = useState(null);
  const [loading, setLoading] = useState(false);
  const homeRef = useRef(null);

  const blogEndpoint = apiUrls?.blog?.getBlogs;

  const fetchBlog = async () => {
    setLoading(true);
    const response = await axios.get(blogEndpoint);
    const data = response.data.data;
    if (!data) return { notFound: true };
    setBlogList(data);
    const allCategories = data?.map((e) => e?.attributes?.type);
    const uniqueCategories = [...new Set(allCategories)];
    setCategories(uniqueCategories);
    setBlogsPerCategory(new Array(uniqueCategories.length).fill(4));
    setClickedSeeMore(new Array(uniqueCategories.length).fill(false));
    setLoading(false);
  };

  useEffect(() => {
    //When "see more" is clicked for a category, the blog list will be updated
    if (oneSelectedCategory.length > 0) {
      setLoading(true);
      const fetchBlogByCategory = async () => {
        const res = await axios.get(
          blogEndpoint +
            `&pagination[page]=1&pagination[pageSize]=100
            &filters[type][$eq]=${oneSelectedCategory[0]}` //calling data for specific category to avoid pagination limits
        );

        const data = res.data.data;

        if (!data) return { notFound: true };
        setBlogList(data);
        setLoading(false);
      };

      fetchBlogByCategory();
    } else {
      fetchBlog();
    }
  }, [oneSelectedCategory]);

  const refsById = useMemo(() => {
    const refs = {};
    categories?.forEach((_, id) => {
      refs[id] = createRef(null);
    });
    return refs;
  }, [categories]);

  return (
    <>
      <PageHeader title={"Blog HPAM"} top />

      <Container maxWidth="lg" style={{ marginBottom: "120px" }}>
        <OptionMenu
          items={categories}
          setSelectedCategory={setSelectedCategory}
          selectedCategory={selectedCategory}
          setSearchText={setSearchText}
          searchText={searchText}
          setTagClicked={setTagClicked}
          setOneSelectedCategory={setOneSelectedCategory}
          oneSelectedCategory={oneSelectedCategory}
          homeRef={homeRef}
        />

        {(oneSelectedCategory?.length > 0
          ? oneSelectedCategory
          : categories
        )?.map((category, index) => {
          const filteredBlogList = blogList?.filter((blog) => {
            if (searchText?.length > 0) {
              return (
                blog?.attributes?.title
                  .toLowerCase()
                  .includes(searchText?.toLowerCase()) &&
                blog?.attributes?.type === category
              );
            } else return blog?.attributes?.type === category;
          });

          return (
            <ArticleSection
              items={
                loading
                  ? []
                  : oneSelectedCategory.length === 1
                  ? filteredBlogList
                  : filteredBlogList?.slice(0, 4) //show only 4 posts per category if no category is selected (see more)
              }
              category={category}
              key={index}
              tag={refsById[index]}
              tagClicked={tagClicked}
              index={index}
              itemsLength={filteredBlogList?.length}
              topCategory={homeRef?.current?.offsetTop}
              selectedCategory={setSelectedCategory}
              featuredBlog={setBlogsPerCategory}
              selectedSeeMore={setOneSelectedCategory}
              oneSelectedCategory={oneSelectedCategory}
              clickedSeeMore={setClickedSeeMore}
            />
          );
        })}
      </Container>
    </>
  );
};
