import React from "react";

import LocationOnIcon from "@mui/icons-material/LocationOn";
import EmailIcon from "@mui/icons-material/Email";
import WhatsAppIcon from '@mui/icons-material/WhatsApp';
import PhoneIcon from '@mui/icons-material/Phone';
import InstagramIcon from "@mui/icons-material/Instagram";
import LinkedInIcon from "@mui/icons-material/LinkedIn";
import YouTubeIcon from "@mui/icons-material/YouTube";
import {
	// appStoreButton,
	// googlePlayButton,
	// kominfoLogo,
	ojkLogo,
} from "../assets";

export const navbarData_id = [
	{ label: "Beranda", route: "/home" },
	{ label: "Tentang Kami", route: "/about-us" },
	{ label: "Produk", route: "/#products" },
	{ label: "Hubungi Kami", route: "/#contact-us" },
	{ label: "Insight", route: "/insight" },
	{
		label: "Karir",
		route: "https://www.linkedin.com/company/hpassetmanagementid",
		target: "_blank",
	},
	{ label: "Blog", route: "/blog" },
];

export const navbarData_en = [
	{ label: "Home", route: "/home" },
	{ label: "About Us", route: "/about-us" },
	{ label: "Products", route: "/#products" },
	{ label: "Contact Us", route: "/#contact-us" },
	{ label: "Insight", route: "/insight" },
	{
		label: "Career",
		route: "https://www.linkedin.com/company/hpassetmanagementid",
		target: "_blank",
	},
	{ label: "Blog", route: "/blog" },
];

export const HpamFooterData_id = [
	{
		title: "All investing involves risk.",
		contentsType: "disclaimer",
		contents: [
			{
				text: `Before investing you should carefully consider the Fund’s investment objectives, risks, charges and expenses. This fund may also allocate a portion of the portfolio to high risk assets which have high risk and return profile. This and other information are available through request for prospectus or at our company’s website. The fund is subject to market risks (volatility) and may not be appropriate for short term investments. Holdings and Sector weightings are subject to constant changes without notice as deemed fit by the investment management team. This document is for sophisticated investor only and not intended for individual private investor. Performance data quoted represent past performance and is no guarantee of future results. Investment returns and principal values may fluctuate so that an investor’s shares, when redeemed, may be worth more or less than their original cost. Current performance may be lower or higher than that shown. All return assume reinvestment of all dividend and capital gain distributions. Index performance is shown for illustrative purpose only. Conventional Non-bank institutions are prohibited from investing directly in index.`,
			},
		],
	},
	{
		title: "PT Henan Putihrai Asset Management",
		contents: [
			{
				icon: <LocationOnIcon style={{ color: "white", fontSize: "24px" }} />,
				text: `Sahid Sudirman Center, 46th Floor \nJl. Jend Sudirman No. 86, Jakarta`,
				url: "https://goo.gl/maps/f9sJQuxYMe95q4oh6",
			},
			{
				icon: <EmailIcon style={{ color: "white", fontSize: "24px" }} />,
				text: "cs@hpam.co.id",
				url: "mailto:cs@hpam.co.id",
			},
			{
				icon: <PhoneIcon style={{ color: "white", fontSize: "24px" }} />,
				text: "(021) 39716699",
			},
			{
				icon: <WhatsAppIcon style={{ color: "white", fontSize: "24px" }} />,
				text: "+62 878-8252-5775",
				url: "https://wa.me/6287882525775",
			},
		],
	},
	{
		title: "Bantuan",
		contents: [
			{ text: "FAQ", url: "https://faq.myhero.id" },
			{
				text: "Hubungi Kami",
				url: "https://api.whatsapp.com/send/?phone=6287882525775",
			},
			{ text: "Syarat & Ketentuan", url: "" },
			{ text: "Kebijakan Privasi", url: "" },
		],
	},
	{
		title: "Lainnya",
		contents: [
			{ text: "Blog", url: "https://hpam.co.id/blog" },
			{ text: "Karir", url: "https://www.linkedin.com/company/hpassetmanagementid" },
			// { text: "Syarat & Ketentuan", url: "" },
			// { text: "Kebijakan Privasi", url: "" },
		],
	},
	{
		title: "Ikuti Kami",
		contentsType: "icon",
		contents: [
			{
				icon: <InstagramIcon style={{ color: "white", fontSize: "24px" }} />,
				url: "https://www.instagram.com/hpam.id",
			},
			{
				icon: <LinkedInIcon style={{ color: "white", fontSize: "24px" }} />,
				url: "https://www.linkedin.com/company/hpassetmanagementid",
			},
			{
				icon: <YouTubeIcon style={{ color: "white", fontSize: "24px" }} />,
				url: "https://www.youtube.com/channel/UCISGbJbqTPVdN9wxPLWhFKg",
			},
		],
	},
	{
		// title: "Download Aplikasi",
		contentsType: "image",
		contents: [
			// {
			//   url: "https://play.google.com/store/apps/details?id=com.hpam.myhero",
			//   imgUrl: googlePlayButton,
			// },
			// {
			//   url: "https://apps.apple.com/id/app/myhero-hpam/id1499297292",
			//   imgUrl: appStoreButton,
			// },
			{
				url: "https://reksadana.ojk.go.id/Public/ManajerInvestasiPublic.aspx?id=HP002",
				imgUrl: ojkLogo,
				text: "KEP-04/BL/MI/2006",
			},
			// {
			//   url: "https://pse.dev.kominfo.go.id/sistem/1259",
			//   imgUrl: kominfoLogo,
			//   text: "00843/DJAI.PSE/04/2018",
			// },
		],
	},
];

export const HpamFooterData_en = [
	{
		title: "All investing involves risk.",
		contentsType: "disclaimer",
		contents: [
			{
				text: `Before investing you should carefully consider the Fund’s investment objectives, risks, charges and expenses. This fund may also allocate a portion of the portfolio to high risk assets which have high risk and return profile. This and other information are available through request for prospectus or at our company’s website. The fund is subject to market risks (volatility) and may not be appropriate for short term investments. Holdings and Sector weightings are subject to constant changes without notice as deemed fit by the investment management team. This document is for sophisticated investor only and not intended for individual private investor. Performance data quoted represent past performance and is no guarantee of future results. Investment returns and principal values may fluctuate so that an investor’s shares, when redeemed, may be worth more or less than their original cost. Current performance may be lower or higher than that shown. All return assume reinvestment of all dividend and capital gain distributions. Index performance is shown for illustrative purpose only. Conventional Non-bank institutions are prohibited from investing directly in index.`,
			},
		],
	},
	{
		title: "PT Henan Putihrai Asset Management",
		contents: [
			{
				icon: <LocationOnIcon style={{ color: "white", fontSize: "24px" }} />,
				text: `Sahid Sudirman Center, 46th Floor \nJl. Jend Sudirman No. 86, Jakarta`,
				url: "https://goo.gl/maps/f9sJQuxYMe95q4oh6",
			},
			{
				icon: <EmailIcon style={{ color: "white", fontSize: "24px" }} />,
				text: "cs@hpam.co.id",
				url: "mailto:cs@hpam.co.id",
			},
			{
				icon: <PhoneIcon style={{ color: "white", fontSize: "24px" }} />,
				text: "(021) 39716699",
			},
			{
				icon: <WhatsAppIcon style={{ color: "white", fontSize: "24px" }} />,
				text: "+62 878-8252-5775",
				url: "https://wa.me/6287882525775",
			},
		],
	},
	{
		title: "Help",
		contents: [
			{ text: "FAQ", url: "https://faq.myhero.id" },
			{
				text: "Contact Us",
				url: "https://api.whatsapp.com/send/?phone=6287882525775",
				ariaLabel: "Contact Us",
			},
			{
				text: "Terms & Conditions",
				url: "https://hpam.co.id",
				ariaLabel: "Terms & Conditions",
			},
			{
				text: "Privacy Policy",
				url: "https://hpam.co.id",
				ariaLabel: "Privacy Policy",
			},
		],
	},
	{
		title: "Others",
		contents: [
			{ text: "Blog", url: "https://hpam.co.id/blog" },
			{ text: "Career", url: "https://www.linkedin.com/company/hpassetmanagementid" },
			// { text: "Syarat & Ketentuan", url: "" },
			// { text: "Kebijakan Privasi", url: "" },
		],
	},
	{
		title: "Follow Us",
		contentsType: "icon",
		contents: [
			{
				icon: <InstagramIcon style={{ color: "white", fontSize: "24px" }} />,
				url: "https://www.instagram.com/hpam.id/",
				ariaLabel: "Instagram",
			},
			{
				icon: <LinkedInIcon style={{ color: "white", fontSize: "24px" }} />,
				url: "https://www.linkedin.com/company/hpassetmanagementid",
				ariaLabel: "LinkedIn",
			},
			{
				icon: <YouTubeIcon style={{ color: "white", fontSize: "24px" }} />,
				url: "https://www.youtube.com/channel/UCISGbJbqTPVdN9wxPLWhFKg",
				ariaLabel: "Youtube",
			},
		],
	},
	{
		// title: "Download Aplikasi",
		contentsType: "image",
		contents: [
			// {
			//   url: "https://play.google.com/store/apps/details?id=com.hpam.myhero",
			//   imgUrl: googlePlayButton,
			// },
			// {
			//   url: "https://apps.apple.com/id/app/myhero-hpam/id1499297292",
			//   imgUrl: appStoreButton,
			// },
			{
				url: "https://reksadana.ojk.go.id/Public/ManajerInvestasiPublic.aspx?id=HP002",
				imgUrl: ojkLogo,
				text: "KEP-04/BL/MI/2006",
			},
			// {
			//   url: "https://pse.dev.kominfo.go.id/sistem/1259",
			//   imgUrl: kominfoLogo,
			//   text: "00843/DJAI.PSE/04/2018",
			// },
		],
	},
];

export const HpsFooterData_id = [
	{
		title: "All investing involves risk.",
		contentsType: "disclaimer",
		contents: [
			{
				text: `Before investing you should carefully consider the Fund’s investment objectives, risks, charges and expenses. This fund may also allocate a portion of the portfolio to high risk assets which have high risk and return profile. This and other information are available through request for prospectus or at our company’s website. The fund is subject to market risks (volatility) and may not be appropriate for short term investments. Holdings and Sector weightings are subject to constant changes without notice as deemed fit by the investment management team. This document is for sophisticated investor only and not intended for individual private investor. Performance data quoted represent past performance and is no guarantee of future results. Investment returns and principal values may fluctuate so that an investor’s shares, when redeemed, may be worth more or less than their original cost. Current performance may be lower or higher than that shown. All return assume reinvestment of all dividend and capital gain distributions. Index performance is shown for illustrative purpose only. Conventional Non-bank institutions are prohibited from investing directly in index.`,
			},
		],
	},
	{
		title: "PT Henan Putihrai Sekuritas",
		contents: [
			{
				icon: <LocationOnIcon style={{ color: "white", fontSize: "24px" }} />,
				text: `Tamara Center, 18th Floor \nJl. Jend Sudirman Kav 24, Jakarta`,
				url: "https://goo.gl/maps/f9sJQuxYMe95q4oh6",
			},
			{
				icon: <EmailIcon style={{ color: "white", fontSize: "24px" }} />,
				text: "cs@hpam.co.id",
				url: "mailto:cs@hpam.co.id",
			},
		],
	},
	{
		title: "Bantuan",
		contents: [
			{ text: "FAQ", url: "https://faq.myhero.id" },
			{
				text: "Hubungi Kami",
				url: "https://api.whatsapp.com/send/?phone=6287882525775",
			},
			{ text: "Syarat & Ketentuan", url: "" },
			{ text: "Kebijakan Privasi", url: "" },
		],
	},
	{
		title: "Lainnya",
		contents: [
			{ text: "Blog", url: "https://blog.myhero.id" },
			{ text: "Karir", url: "https://www.linkedin.com/company/hpfinancials" },
			// { text: "Syarat & Ketentuan", url: "" },
			// { text: "Kebijakan Privasi", url: "" },
		],
	},
	{
		title: "Ikuti Kami",
		contentsType: "icon",
		contents: [
			{
				icon: <InstagramIcon style={{ color: "white", fontSize: "24px" }} />,
				url: "https://www.instagram.com/hpassetmanagement/",
			},
			{
				icon: <LinkedInIcon style={{ color: "white", fontSize: "24px" }} />,
				url: "https://www.linkedin.com/company/hpfinancials/",
			},
			{
				icon: <YouTubeIcon style={{ color: "white", fontSize: "24px" }} />,
				url: "https://www.youtube.com/channel/UCISGbJbqTPVdN9wxPLWhFKg",
			},
		],
	},
	{
		// title: "Download Aplikasi",
		contentsType: "image",
		contents: [
			// {
			//   url: "https://play.google.com/store/apps/details?id=com.hpam.myhero",
			//   imgUrl: googlePlayButton,
			// },
			// {
			//   url: "https://apps.apple.com/id/app/myhero-hpam/id1499297292",
			//   imgUrl: appStoreButton,
			// },
			{
				url: "https://reksadana.ojk.go.id/Public/ManajerInvestasiPublic.aspx?id=HP002",
				imgUrl: ojkLogo,
				text: "KEP-04/BL/MI/2006",
			},
			// {
			//   url: "https://pse.dev.kominfo.go.id/sistem/1259",
			//   imgUrl: kominfoLogo,
			//   text: "00843/DJAI.PSE/04/2018",
			// },
		],
	},
];

export const HpsFooterData_en = [
	{
		title: "All investing involves risk.",
		contentsType: "disclaimer",
		contents: [
			{
				text: `Before investing you should carefully consider the Fund’s investment objectives, risks, charges and expenses. This fund may also allocate a portion of the portfolio to high risk assets which have high risk and return profile. This and other information are available through request for prospectus or at our company’s website. The fund is subject to market risks (volatility) and may not be appropriate for short term investments. Holdings and Sector weightings are subject to constant changes without notice as deemed fit by the investment management team. This document is for sophisticated investor only and not intended for individual private investor. Performance data quoted represent past performance and is no guarantee of future results. Investment returns and principal values may fluctuate so that an investor’s shares, when redeemed, may be worth more or less than their original cost. Current performance may be lower or higher than that shown. All return assume reinvestment of all dividend and capital gain distributions. Index performance is shown for illustrative purpose only. Conventional Non-bank institutions are prohibited from investing directly in index.`,
			},
		],
	},
	{
		title: "PT Henan Putihrai Sekuritas",
		contents: [
			{
				icon: <LocationOnIcon style={{ color: "white", fontSize: "24px" }} />,
				text: `Sahid Sudirman Center, 46th Floor \nJl. Jend Sudirman No. 24, Jakarta`,
				url: "https://goo.gl/maps/f9sJQuxYMe95q4oh6",
			},
			{
				icon: <EmailIcon style={{ color: "white", fontSize: "24px" }} />,
				text: "cs@hpam.co.id",
				url: "mailto:cs@hpam.co.id",
			},
		],
	},
	{
		title: "Help",
		contents: [
			{ text: "FAQ", url: "https://faq.myhero.id" },
			{
				text: "Contact Us",
				url: "https://api.whatsapp.com/send/?phone=6287882525775",
			},
			{ text: "Syarat & Ketentuan", url: "" },
			{ text: "Kebijakan Privasi", url: "" },
		],
	},
	{
		title: "Others",
		contents: [
			{ text: "Blog", url: "https://blog.myhero.id" },
			{ text: "Career", url: "https://www.linkedin.com/company/hpfinancials" },
			// { text: "Syarat & Ketentuan", url: "" },
			// { text: "Kebijakan Privasi", url: "" },
		],
	},
	{
		title: "Follow Us",
		contentsType: "icon",
		contents: [
			{
				icon: <InstagramIcon style={{ color: "white", fontSize: "24px" }} />,
				url: "https://www.instagram.com/hpassetmanagement/",
			},
			{
				icon: <LinkedInIcon style={{ color: "white", fontSize: "24px" }} />,
				url: "https://www.linkedin.com/company/hpfinancials/",
			},
			{
				icon: <YouTubeIcon style={{ color: "white", fontSize: "24px" }} />,
				url: "https://www.youtube.com/channel/UCISGbJbqTPVdN9wxPLWhFKg",
			},
		],
	},
	{
		// title: "Download Aplikasi",
		contentsType: "image",
		contents: [
			// {
			//   url: "https://play.google.com/store/apps/details?id=com.hpam.myhero",
			//   imgUrl: googlePlayButton,
			// },
			// {
			//   url: "https://apps.apple.com/id/app/myhero-hpam/id1499297292",
			//   imgUrl: appStoreButton,
			// },
			{
				url: "https://reksadana.ojk.go.id/Public/ManajerInvestasiPublic.aspx?id=HP002",
				imgUrl: ojkLogo,
				text: "KEP-04/BL/MI/2006",
			},
			// {
			//   url: "https://pse.dev.kominfo.go.id/sistem/1259",
			//   imgUrl: kominfoLogo,
			//   text: "00843/DJAI.PSE/04/2018",
			// },
		],
	},
];

export const fundType = {
	Obligasi: "Bond",
	Saham: "Stock",
	"Pasar Uang": "Money Market",
	Campuran: "Mixed",
};
